var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.assignmentLoading, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c("v-btn", _vm._g({ attrs: { color: "primary" } }, on), [
                _vm._v("Hand-in"),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.submitDialog,
        callback: function ($$v) {
          _vm.submitDialog = $$v
        },
        expression: "submitDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v(_vm._s(_vm.bundleData.long_id))]
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex flex-column mt-3" },
              [
                _c(
                  "v-alert",
                  { attrs: { type: "info", outlined: "" } },
                  [
                    _vm._v(
                      " The content of the following files / folders will be handed in, please make sure they exist otherwise hand-in will not succeed! "
                    ),
                    _c(
                      "v-expansion-panels",
                      {
                        staticClass: "w-100",
                        attrs: { value: [0], flat: "", multiple: "" },
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          [
                            _c(
                              "v-expansion-panel-header",
                              { staticClass: "px-0" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "secondary--text font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      "Files (" +
                                        _vm._s(
                                          _vm.bundleData.objects.files.length
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "pa-0", attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item-group",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: { color: "secondary" },
                                      },
                                      _vm._l(
                                        _vm.bundleData.objects.files,
                                        function (file) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: file,
                                              staticClass: "pa-0",
                                              staticStyle: {
                                                "max-height": "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-file-outline")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass: "subtitle-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(file.slice(6))
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v(
                    " Please provide a hand-in identifier (e.g. exam code, email address, etc..) that is in line with your course policy and allows the instructor to match your work with your identifier. If unsure, please discuss the appropriate identifier with your instructor. "
                  ),
                ]),
                _c("v-text-field", {
                  staticClass: "mt-3",
                  attrs: {
                    autofocus: "",
                    label: "Hand-in identifier",
                    outlined: "",
                  },
                  model: {
                    value: _vm.handinIdentifier,
                    callback: function ($$v) {
                      _vm.handinIdentifier = $$v
                    },
                    expression: "handinIdentifier",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.handinIdentifier,
                    loading: _vm.assignmentLoading,
                    color: "primary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitAssignment(
                        _vm.handinIdentifier,
                        _vm.assignmentShortId,
                        _vm.bundleData.bid
                      )
                    },
                  },
                },
                [_vm._v(" Hand-in ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.assignmentLoading,
                    color: "secondary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.submitDialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }